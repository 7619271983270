import ContactForm from './ContactForm';
import { contacts } from '../../pages/Home/config';

const Contacts = () => {
  return (
    <div className="contacts">
      <div className="container">
        <div className="contacts-inner">
          <div className="info">
            <h3 className="title">{contacts.title}</h3>
            <p className="description line-height">{contacts.description}</p>
            <p className="sub-description line-height">{contacts.subDescription}</p>
          </div>
          <ContactForm />
        </div>
      </div>
    </div>
  );
};

export default Contacts;
