const Partners = ({ data }) => {
  return (
    <div className="partners">
      <div className="container">
        <div className="partners-inner">
          <div className="partners-list">
            {data.map(item => (
              <div className="partners-item" key={item}>
                <div className="image-wrapper">
                  <img src={`/assets/images/Partners/${item}.png`} alt={item} />
                </div>
                <span>{item.label}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Partners
