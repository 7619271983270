
export const carouselObj = {
  carousel: [
    {
      image: 'banner-1',
      title: 'Meet our',
      heightLight: 'platform',
      subTitle: 'To ensure the smooth operation of your betting and casino modules in line with the flexible bonus engine , productive back office and the payment gateway you need a robust Platform',
      button: {
        text: 'Contact Us',
        type: 'Contact',
      }
    },
    {
      image: 'banner-2',
      title: 'Meet our',
      heightLight: 'platform',
      subTitle: 'Fully-automated process for creating sports, regions, leagues and teams',
      button: {
        text: 'Contact Us',
        type: 'Contact',
      }
    },
    {
      image: 'banner-3',
      title: 'Meet our',
      heightLight: 'platform',
      subTitle: 'Rewarding risk management',
      button: {
        text: 'Contact Us',
        type: 'Contact',
      }
    },
    {
      image: 'banner-4',
      title: 'Meet our',
      heightLight: 'platform',
      subTitle: 'Marketing tools, strong back office and inviting bonus engine',
      button: {
        text: 'Contact Us',
        type: 'Contact',
      }
    },
  ]
};

export const stepsObj = {
  title: 'Advantages of',
  heightLight: 'platform',
  steps: [
    { title: 'Top usability', description: ['Flexible API Integration with 3rd party products', 'Based on HTML 5, no 3rd party plugins', 'Multiple currencies and languages', 'Turn-Key and White Label options'] },
    { title: 'Business intelligences', description: ['Real time analytics', 'Predefined and customizable reports', 'Gaming statistics'] },
    { title: 'Strong in the core', description: ['99% uptime', 'Maximum security', 'Withstand large numbers of users at the same time with low latency'] },
  ],
};

export const advantagesObj = {
  title: 'Features &',
  heightLight: 'benefits',
  subTitleWhite: 'Engaging bonus engine  and advanced back office',
  subTitle: 'Gamble Beast Sportsbook is built to bring benefits both to operators and their players',
  concated: true,
  benefitsLeft: [
    'Account management',
    'Player management',
    'Translation management',
    'Content management',
    'Adaptive risk management',
  ],
  benefitsRight: [
    'Payment management',
    'Partner management',
    'Role management',
    'Messaging module',
  ],
};

export const keyComponentsObj = {
  title: 'Key',
  heightLight: 'components',
  keyComponents: [
    {
      title: 'Agent system',
      description: 'Feel the power of multi build your own multilevel network and attract hard-to-reach players with Agento by Gamble Beast.',
    },
    {
      title: 'Bonus system',
      description: 'Encourage your players with the wide range and flexible bonus system. Welcome bonus, Free Spins, Cash out bonuses are ready to operate!',
    },
    {
      title: 'CRM',
      description: 'Tailor your marketing and sales campaigns with one of the best Customer Relationship Management System.',
    },
    {
      title: 'Affilate system',
      description: 'Easy to integrate, total control. Grow your business with our forefront affiliate system.',
    },
  ],
}