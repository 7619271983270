import Advantages from '../../components/Advantages';
import Carousel from '../../components/Carousel';
import Cards from '../../components/common/Cards';
import SectionTitle from '../../components/common/SectionTitle';
import { advantagesObj, carouselObj, greatThingsObj, productsObj } from './config';

export const companySections = [
  {
    checkRender: carouselObj.carousel.length > 0,
    components: [
      { Compoenent: Carousel, props: { data: carouselObj.carousel } },
    ],
  },
  {
    checkRender: productsObj.products.length > 0,
    components: [
      { Compoenent: SectionTitle, props: { commonText: productsObj.title, highlighText: productsObj.heightLight } },
      { Compoenent: Cards, props: { cards: productsObj.products, darkMode: false } },
    ]
  },
  {
    checkRender: advantagesObj.features.length > 0,
    components: [
      { Compoenent: SectionTitle, props: { commonText: advantagesObj.title, highlighText: advantagesObj.heightLight } },
      { Compoenent: Advantages, props: { data: { features: advantagesObj.features } } },
    ]
  },
  {
    checkRender: greatThingsObj.greatThings.length > 0,
    primary: true,
    components: [
      { Compoenent: SectionTitle, props: { commonText: greatThingsObj.title } },
      { Compoenent: Cards, props: { cards: greatThingsObj.greatThings, darkMode: false } },
    ],
  },
];
