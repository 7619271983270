import ContactUs from './ContactUs';
import FindUs from './FindUs';
import { contactUs, findUs } from './config';

const FooterContact = () => {
  return (
    <div className="footer-contact">
      <div className="container">
        <div className="footer-contact-inner">
          {contactUs.contacts.length > 0 && (
            <ContactUs data={contactUs} />
          )}
          {findUs.socials.length > 0 && (
            <FindUs data={findUs} />
          )}
        </div>
      </div>
    </div>
  );
};

export default FooterContact;
