import Advantages from '../../components/Advantages';
import Carousel from '../../components/Carousel';
import SectionTitle from '../../components/common/SectionTitle';
import Steps from '../../components/common/Steps';
import KeyComponents from '../../components/KeyComponents';
import { carouselObj, advantagesObj, keyComponentsObj, stepsObj } from './config';

export const platformSections = [
  {
    checkRender: carouselObj.carousel.length > 0,
    components: [
      { Compoenent: Carousel, props: { data: carouselObj.carousel } },
    ],
  },
  {
    checkRender: stepsObj.steps.length > 0,
    components: [
      { Compoenent: SectionTitle, props: { commonText: stepsObj.title, highlighText: stepsObj.heightLight } },
      { Compoenent: Steps, props: { steps: stepsObj.steps } },
    ]
  },
  {
    checkRender: advantagesObj.benefitsLeft.length > 0 || advantagesObj.benefitsRight.length > 0,
    components: [
      { Compoenent: SectionTitle, props: { commonText: advantagesObj.title, highlighText: advantagesObj.heightLight, subTitle: advantagesObj.subTitle, subTitleWhite:  advantagesObj.subTitleWhite} },
      { Compoenent: Advantages, props: { data: { benefits: { left: advantagesObj.benefitsLeft, right: advantagesObj.benefitsRight } }, concated: advantagesObj.concated } },
    ]
  },
  {
    checkRender: keyComponentsObj.keyComponents.length > 0,
    components: [
      { Compoenent: SectionTitle, props: { commonText: keyComponentsObj.title, highlighText: keyComponentsObj.heightLight } },
      { Compoenent: KeyComponents, props: { data: keyComponentsObj.keyComponents } },
    ]
  },
];
