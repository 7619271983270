import Payments from '../Payments';
import FooterContact from './FooterContact';
import Copyright from './Copyright';
import { copyright } from './config';
import { paymentsObj } from '../Payments/config';

const Footer = () => {
  return (
    <div className="app-footer">
      {paymentsObj.payments.length > 0 && (
        <div className="section">
          <Payments data={paymentsObj} />
      </div>)}
      <FooterContact />
      <Copyright data={copyright} />
    </div>
  );
};

export default Footer;
