export const productsObj = {
  title: 'Amazing',
  heightLight: 'products',
  products: [
    {
      icon: 'sportsbook', title: 'Sportsbook',
      description: {
        text: 'Fully managed and highly flexible Sportsbook to drive your successful betting business with wide range of sports and markets.',
        bold: false,
      },
      hasButton: true,
      buttonText: 'View demo',
      link: 'https://demo.gamblebeast.com/sport/prematch',
      targetBlank: true,
    },
    {
      icon: 'platform', title: 'Platform',
      description: {
        text: 'Robust and advanced Platform to ensure the smooth operation of your betting and casino mod ules.',
        bold: false,
      },
      hasButton: true,
      buttonText: 'View page',
      link: '/platform',
    },
    {
      icon: 'casino', title: 'Casino',
      description: {
        text: 'An outstanding Casino solution, combining engaging content with technologies powered to boost your profits.',
        bold: false,
      },
      hasButton: true,
      buttonText: 'View demo',
      link: 'https://demo.gamblebeast.com/casino/games',
      targetBlank: true,
    },
  ],
};

export const stepsObj = {
  title: 'Simple steps to start your',
  heightLight: 'profitable business with us',
  steps: [
    { title: 'Contact us', description: 'Just drop us your contacts and we will reach out to you' },
    { title: 'Assessing your needs', description: 'We don\'t assume what you need, so we listen to you carefully and assess your needs' },
    { title: 'Defining the solution', description: 'We will come up with the ideal solution right for you to meet your expectations and cover your needs' },
    { title: 'Setting it up', description: 'We will take care of initial set up and branding. It\'s easy and seamless with us' },
  ],
};

export const greatThingsObj = {
  title: 'How we make great things',
  greatThings: [
    {
      icon: 'speed', title: 'Go-To-Market Speed',
      description: {
        text: 'It’s completely designed to successfully set up and expand your business throughout the market both via website and bet shops.',
        bold: false,
      }
    },
    {
      icon: 'expertise', title: 'Expertise',
      description: {
        text: 'Product Design, Engineering and Quality Assurance are blended into experienced teams that extend your innovation prowess.',
        bold: false,
      }
    },
    {
      icon: 'transparency', title: 'Transparency',
      description: {
        text: 'Metrics-driven Product Management with our Power Up app helps everyone stay on the same page.',
        bold: false,
      }
    },
    {
      icon: 'scalability', title: 'Scalability',
      description: {
        text: 'Agile training and DevOps disciplines help scale your business capacity to manage more products with current staff.',
        bold: false,
      }
    },
  ],
};

export const contacts = {
  title: 'Let’s talk',
  description: 'We will be happy to contact You and let our professional team  take down your requirements, listen to your needs and find the best match for you',
  subDescription: 'Please leave us your name, email and other contact information',
  contactForm: [
    { name: 'Name', type: 'text', placeholder: 'Your name' },
    { name: 'Email', type: 'email', placeholder: 'Email' },
    { name: 'Mobile', type: 'number', placeholder: 'Mobile' },
    { name: 'Subject', type: 'text', placeholder: 'Subject' },
    { name: 'Message', placeholder: 'Message', textarea: true },
  ],
  button: {
    text: 'Send',
    primary: true,
  }
};

export const carouselObj = {
  carousel: [
    {
      image: 'banner-1',
      title: 'Sportsbetting & igaming',
      heightLight: 'solution provider',
      subTitle: 'You are here, because We have what You need for your betting and gaming  business',
      button: {
        text: 'Contact Us',
        type: 'Contact',
        primary: true
      }
    },
    {
      image: 'banner-2',
      title: 'Sportsbetting & igaming',
      heightLight: 'solution provider',
      subTitle: 'Flexibility and Competitive markets and odds',
      button: {
        text: 'Contact Us',
        type: 'Contact',
        primary: true
      }
    },
    {
      image: 'banner-3',
      title: 'Sportsbetting & igaming',
      heightLight: 'solution provider',
      subTitle: 'Customizable odds and matches based on your market needs',
      button: {
        text: 'Contact Us',
        type: 'Contact',
        primary: true
      }
    },
    {
      image: 'banner-4',
      title: 'Sportsbetting & igaming',
      heightLight: 'solution provider',
      subTitle: 'Rewarding risk management and Strong reporting with standard and custom reports',
      button: {
        text: 'Contact Us',
        type: 'Contact',
        primary: true
      }
    },
  ]
};
