import { useEffect, useState, useMemo, useCallback } from  'react';
import Input from '../common/Input';
import Button from '../common/Button';
import { contacts } from '../../pages/Home/config';
import axios from 'axios';
import Modal from '../common/Modal';

const { contactForm, button }  = contacts;

const ContactForm = ({ onClose }) => {
  const [form, setForm] = useState({});
  const [modal, setModal] = useState(false)

  const initalForm = useMemo(() => {
    return contactForm.reduce((acc, curr) => {
      acc[curr.name] = '';
      return acc;
    }, {});
  }, []);

  useEffect(() => {
    setForm(initalForm);
  }, [initalForm]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const respose = await axios.post('https://apps.efugames.com/api/4/en/Client/SaveFeedback', {
        PartnerId: 3,
        ...form
      });
      if (!respose.data?.HasError) {
        setModal(true);
      }
      setForm({});
    } catch (error) {
      console.log(error);
    }
  };

  const onCloseModal = useCallback(() => {
    setModal(false);
    if (onClose) {
      onClose();
    }
  }, [setModal, onClose]);
  

  return (
    <>
      {modal && <Modal isOpen={modal}>
        <Modal.Header onClose={onCloseModal} />
        <Modal.Body>
        <span
        style={{
              display: 'block',
              textAlign: 'center',
              color: '#7cff6c',
              fontSize: '22px',
              paddingBottom: '20px',
        }}>
          Your Message has beeen sent successfully
        </span>
        </Modal.Body>
      </Modal>}
      <div className="contact-form">
        {contactForm.map(item => (
          <Input
            key={item.placeholder}
            form={form}
            onChnage={handleChange}
            {...item}
          />
        ))}
        <Button disable={!Object.values(form).every(i => i.trim())} onClick={handleSubmit} {...button} />
      </div>
    </>
  );
};

export default ContactForm;
