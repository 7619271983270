
const Platform = ({ platformSections }) => {
  return (
    <div className="platform">
      {platformSections.map(({ checkRender, components, primary }, index) => (
        checkRender && <div key={index} className={`section ${primary ? 'primary' : ''}`}>
          {components.map(({ Compoenent, props }, index) => <Compoenent key={index} {...props} />)}
        </div>
      ))}
    </div>
  );
};

export default Platform;
