import { useContext, useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { Link, useLocation } from 'react-router-dom';
import { WindowSizeContext } from '../../App';
import useBodyClass from '../../hooks/useBodyClass';
import { useOutsideClick } from '../../hooks/useOutsideClick';
import { navigation } from './config';

const Navigation = () => {
  const { pathname } = useLocation();
  const { width } = useContext(WindowSizeContext);
  const history = useHistory();
  const [expended, setExpended] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const node = useRef();
  useBodyClass('overflow-hidden', expended && width < 860);

  useEffect(() => {
    if (expended && width > 860) {
      setExpended(false);
    }
  }, [expended, width]);

  useEffect(() => {
    if(!expended && collapsed) {
      setCollapsed(false);
    }
  }, [expended, collapsed]);

  useOutsideClick(node, setExpended);

  const onCollapsedHandler = (e, condition) => {
    e.stopPropagation();
    if(!condition) return;
    setCollapsed(prev => !prev);
  };

  const onLinkHandler = (e, path) => {
    e.stopPropagation();
    history.push(path);
    setCollapsed(false);
    setExpended(false);
  };

  return (
    <nav className={`navigation ${width <= 860 ? 'burger' : ''}`}>
      {width > 860 ? (
        navigation.length > 0 && navigation.map(nav => (
          <Link
            key={nav.pageName}
            to={nav.route}
            className={`nav-item ${pathname === nav.route ? 'active' : ''}`}
          >
            {nav.pageName}
          </Link >
        ))
      ) : (
        <div className='burger-wrapper' onClick={() => setExpended(prev => !prev)}>
          <span />
          <span />
          <span />
        </div>
      )}
      {expended && (
        <div ref={node} className='expended-navigation'>
          {navigation.length > 0 && navigation.map((nav, index) => (
            <div onClick={(e) => onCollapsedHandler(e, nav.defaultPageName)} className={`nav-item-holder ${nav.defaultPageName ?  'column' : ''} ${collapsed && nav.defaultPageName ? 'active': ''}`} key={index}>
              <div className="n-item-info">
                <span onClick={(e) => onLinkHandler(e, nav.route || nav.defaultRoute)} className='nav-i-text'>{nav.pageName || nav.defaultPageName}</span>
                {nav.defaultPageName && <i className='icon-arrow-down' />}
              </div>
              {nav.options?.length > 0 && (
                <div className='sub-nav-item'>
                  <div className="sub-nav-list">
                    {nav.options.map((subNav, index) => (
                      <span onClick={(e) => onLinkHandler(e, subNav.route)} className='s-nav-link' key={index}>{subNav.pageName}</span>
                    ))}
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </nav>
  );
};

export default Navigation;
