import Advantages from '../../components/Advantages';
import Carousel from '../../components/Carousel';
import Cards from '../../components/common/Cards';
import SectionTitle from '../../components/common/SectionTitle';
import KeyComponents from '../../components/KeyComponents';
import ProductSpecifications from '../../components/ProductSpecifications';
import { carouselObj, productsObj, advantagesObj, productSpecificationsObj, keyComponentsObj } from './config';

export const sportsBookSections = [
  {
    checkRender: carouselObj.carousel.length > 0,
    components: [
      { Compoenent: Carousel, props: { data: carouselObj.carousel } },
    ],
  },
  {
    checkRender: productsObj.products.length > 0,
    components: [
      { Compoenent: SectionTitle, props: { commonText: productsObj.title, highlighText: productsObj.heightLight, subTitle: productsObj.subTitle } },
      { Compoenent: Cards, props: { cards: productsObj.products, darkMode: false } },
    ]
  },
  {
    checkRender: advantagesObj.features.length > 0 || advantagesObj.benefitsLeft.length > 0 || advantagesObj.benefitsRight.length > 0,
    components: [
      { Compoenent: SectionTitle, props: { commonText: advantagesObj.title, highlighText: advantagesObj.heightLight, subTitle: advantagesObj.subTitle } },
      { Compoenent: Advantages, props: { data: { features: advantagesObj.features, benefits: { left: advantagesObj.benefitsLeft, right: advantagesObj.benefitsRight } } } },
    ]
  },
  {
    checkRender: productSpecificationsObj.specifications.length > 0 || productSpecificationsObj.benefitsLeft.length > 0 || productSpecificationsObj.benefitsRight.length > 0,
    primary: true,
    components: [
      { Compoenent: SectionTitle, props: { commonText: productSpecificationsObj.title } },
      { Compoenent: ProductSpecifications, props: { data: { specifications: productSpecificationsObj.specifications, benefits: { left: productSpecificationsObj.benefitsLeft, right: productSpecificationsObj.benefitsRight } } } },
    ]
  },
  {
    checkRender: keyComponentsObj.keyComponents.length > 0,
    components: [
      { Compoenent: SectionTitle, props: { commonText: keyComponentsObj.title, highlighText: keyComponentsObj.heightLight } },
      { Compoenent: KeyComponents, props: { data: keyComponentsObj.keyComponents } },
    ]
  },
];
