import CardItem from './CardItem';

const Cards = ({ cards, darkMode }) => {
  return (
    <div className={`cards ${darkMode ? 'dark' : ''}`}>
      <div className="container">
        <div className="cards-inner">
          {cards.map((card, index) => (
            <CardItem
              key={index}
              card={card}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Cards;
