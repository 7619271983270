import { useEffect } from "react";

const useBodyClass = (className, condition) => {

  useEffect(() => {
    if (condition) {
      document.body.classList.add(className);
    } else {
      document.body.classList.remove(className);
    }
  }, [condition, className]);
};

export default useBodyClass;
