const ContactUs = ({ data: { title, contacts } }) => {
  return (
    <div className="contact-us">
      <h3 className="title">{title}</h3>
      <div className="contacts">
        {contacts.map(({ icon, contact, attr }) => (
          <div className="contact-item" key={contact}>
            <i className={`icon-${icon}`} />
            <a href={attr}>{contact}</a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ContactUs;
