import { useState, useEffect, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Button from '../common/Button';
import ListOfFeatures from '../common/ListOfFeatures';
import Modal from '../common/Modal';
import SectionTitle from '../common/SectionTitle';
import ContactForm from '../Contacts/ContactForm';

const Carousel = ({ data }) => {
  const history = useHistory();
  const [activeIndex, setActiveIndex] = useState(0);
  const { pathname } = useLocation();
  const [modal, setModal] = useState(false);

  const getBannerPath = path => path === '/' ? '/home' : path;

  useEffect(() => {
    let interval = setInterval(() => {
      if (activeIndex === data.length - 1) {
        setActiveIndex(0);
      } else {
        setActiveIndex(activeIndex + 1);
      }
    }, 2000);
    return () => {
      clearInterval(interval);
  }
  }, [activeIndex, data.length]);

  const openContactForm = () => {
    setModal(true);
  };

  const handleClick = ({ link, targetBlank, type }) => {
    if (link) {
      if (link.startsWith('http')) {
        if (targetBlank) {
          window.open(link, '_blank');
        } else {
          window.location.href = link;
        }
      } else {
        history.push(link);
      }
    }
    if (type === 'Contact') openContactForm();
  };

  const onCloseModal = useCallback(() => setModal(false), [setModal]);

  return (
    <>
      {modal && <Modal isOpen={modal}>
        <Modal.Header onClose={onCloseModal} />
        <Modal.Body>
          <ContactForm onClose={onCloseModal} />
        </Modal.Body>
      </Modal>}
      <div className="carousel">
        <div className="container">
          <div className="carousel-inner">
            <div className="carousel-item">
              <div className="left">
                <SectionTitle
                  commonText={data[activeIndex].title}
                  highlighText={data[activeIndex].heightLight}
                  subTitle={data[activeIndex].subTitle}
                  lineHeight
                />
                {data[activeIndex]?.button && (
                  <Button
                    text={data[activeIndex].button.text}
                    primary={data[activeIndex].button.primary}
                    onClick={handleClick.bind(null, {link: data[activeIndex].button?.link, targetBlank: data[activeIndex].button?.targetBlank, type: data[activeIndex].button?.type} )}
                  />
                )}
              </div>
              <div className={`right ${data[activeIndex].listOfFeatures?.length ? 'features' : ''}`}>
                {data[activeIndex]?.image && (
                  <img src={(`/assets/images/Carousel${getBannerPath(pathname)}/${data[activeIndex].image}.png`)} alt={data[activeIndex].image} />
                )}
                {data[activeIndex].listOfFeatures?.length && (
                  data[activeIndex].listOfFeatures.map((listOfFeature, index) => <ListOfFeatures data={listOfFeature} key={index} />)
                )}
              </div>
            </div>
            {data.length > 1 && (
              <div className="dots-wrapper">
                {data.map((_, index) => {
                  return (
                    <div
                      key={index}
                      className={`dot ${activeIndex === index ? 'active' : ''}`}
                      onClick={() => setActiveIndex(index)}
                    />
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Carousel;
