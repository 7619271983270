const SectionTitle = ({ commonText,  highlighText, subTitle, lineHeight, subTitleWhite }) => {
  return (
    <div className="section-title">
      <h3 className="title">
        <span className="common">{commonText}</span>
        {highlighText && (
          <span className="highligh">{highlighText}</span>
        )}
      </h3>
      {subTitleWhite && (
        <h4 className={`sub-title-white ${lineHeight ? 'line-height' : ''}`}>{subTitleWhite}</h4>
      )}
      {subTitle && (
        <h4 className={`sub-title ${lineHeight ? 'line-height' : ''}`}>{subTitle}</h4>
      )}
    </div>
  );
};

export default SectionTitle;
