import React from "react";
import { useLocation } from "react-router";
import KeyInfo from "../common/KeyInfo";

const KeyComponents = ({ data }) => {
  const { pathname } = useLocation();
  const hasImage = data.some(item => item.image);
  return (
    <div className="key-components">
      <div className="container">
        <div className={`key-components-inner ${hasImage ? '' : 'no-image'}`}>
          {data.map(({ image, hasSubtract, ...keyInfo }, index) => (
            <div className={`item ${index % 2 === 0 ? 'even' : 'odd'} ${hasSubtract ? 'subtract' : ''}`} key={index}>
            <KeyInfo data={{ ...keyInfo, index }} />
             {image && <div className="image-wrapper">
              <img src={`/assets/images/KeyComponents${pathname}/${image}.png`} alt={image} />
             </div>}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default KeyComponents;