const Benefits = ({ benefits: { left, right }, concated }) => {
  return (
    <div className={`benefits ${concated ? 'concated' : ''}`}>
      <div className="benefits-container">
        {left?.length > 0 && (
          <div className="benefits-wrapper">
            {left.map((item, index) => (
              <div className="benefits-item" key={index}>
                <div />
                <span className="line-height">{item}</span>
              </div>
            ))}
          </div>
        )}
        {right?.length > 0 && (
          <div className="benefits-wrapper">
            {right.map((item, index) => (
              <div className="benefits-item" key={index}>
                <div />
                <span className="line-height">{item}</span>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Benefits;
