
export const carouselObj = {
  carousel: [
    {
      image: 'banner-1',
      title: 'Meet our',
      heightLight: 'sportsbook',
      subTitle: 'Set up and expand your betting business both online and via bet shops  using our award-winning, flexible and dynamic Sportsbook.',
      button: {
        text: 'View Demo',
        primary: true,
        link: 'https://demo.gamblebeast.com/sport/prematch',
        targetBlank: true,
      }
    },
    {
      image: 'banner-2',
      title: 'Meet our',
      heightLight: 'sportsbook',
      subTitle: 'Marketing tools, strong back office and inviting bonus engine',
      button: {
        text: 'View Demo',
        primary: true,
        link: 'https://demo.gamblebeast.com/sport/prematch',
        targetBlank: true,
      }
    },
    {
      image: 'banner-3',
      title: 'Meet our',
      heightLight: 'sportsbook',
      subTitle: 'Fully-automated process for creating sports, regions, leagues and teams',
      button: {
        text: 'View Demo',
        primary: true,
        link: 'https://demo.gamblebeast.com/sport/prematch',
        targetBlank: true,
      }
    },
    {
      image: 'banner-4',
      title: 'Meet our',
      heightLight: 'sportsbook',
      subTitle: 'Wide range of markets, including fast and special markets for each sport',
      button: {
        text: 'View Demo',
        primary: true,
        link: 'https://demo.gamblebeast.com/sport/prematch',
        targetBlank: true,
      }
    },
  ]
};

export const productsObj = {
  title: 'Why choose our',
  heightLight: 'sportsbook',
  subTitle: 'It’s completely designed to successfully set up and expand your business throughout the market both via website and betting shops.',
  products: [
    {
      icon: 'markets', title: 'Markets',
      description: {
        text: 'Complete range of sports, events and markets',
        bold: false,
      },
      hasButton: false
    },
    {
      icon: 'odds', title: 'Odds management',
      description: {
        text: 'Best margins and accurate management of odds, limits and risks. Opportunity of setting your own odds with your preferred margin',
        bold: false,
      },
      hasButton: false
    },
    {
      icon: 'trader', title: 'In-house traders',
      description: {
        text: 'Highly accurate odds with the best margins based on the market and game, set by our 300+ in-house traders and own robots',
        bold: false,
      },
      hasButton: false
    },
  ],
};

export const advantagesObj = {
  title: 'Features &',
  heightLight: 'benefits',
  subTitle: 'Gamble Beast Sportsbook is built to bring benefits both to operators and their players',
  features: [
    { number: '+500 000', text: 'Events' },
    { number: '+25 000', text: 'Live Events' },
    { number: '+45 000', text: 'Pre-match events' },
    { number: '+60', text: 'Sport types' },
  ],
  benefitsLeft: [
    'Flexibility',
    'Competitive markets and odds',
    'Effective game and odds management',
    'Customizable odds and matches based on your market needs',
    'Fully-automated process for creating sports, regions, leagues and teams',
    'Wide range of markets, including fast and special markets for each sport',
    'Various odds types: Decimal, Fractional, American, Indo, Malay, Honk Kong',
    'Strong reporting with standard and custom reports',
    '24/7 multilingual support',
  ],
  benefitsRight: [
    'Wide range of markets, including fast and special markets for each sport',
    'Various odds types: Decimal, Fractional, American, Indo, Malay, Honk Kong',
    'Live streaming',
    'Cross-betting - opportunity to place bets on favorite teams playing in two different matches',
    'Mobile version, iOS and Android apps',
    'Customizable interface – players can easily customize the look of the website among defined options fitting to their individual preferences',
    'Cross-betting – opportunity to place bets on favorite teams playing in two different matches',
  ],
};

export const productSpecificationsObj = {
  title: 'Product specifications',
  specifications: [
    {
      icon: 'fraud',
      description: {
        text: 'Fraud detection and prevention',
        bold: true,
      }
    },
    {
      icon: 'partnership',
      description: {
        text: 'API integration',
        bold: true,
      }
    },
    {
      icon: 'profitability',
      description: {
        text: 'Data analytics',
        bold: true,
      }
    },
    {
      icon: 'video',
      description: {
        text: 'Video coverage',
        bold: true,
      }
    },
  ],
  benefitsLeft: [
    'Up to date live matches calendar',
    'Odds and match markets calculated in-house',
    'Possibility of importing odds from external sources',
    'Easy bonus, promotions, jackpot creation engine',
  ],
  benefitsRight: [
    'Prevention of the harmful behavior of clients',
    'E-mail and SMS marketing gateways',
    'Risk-threshold limits check for each ticket',
    'Easy integration to any platform',
  ],
};

export const keyComponentsObj = {
  title: 'Key',
  heightLight: 'components',
  keyComponents: [
    {
      title: 'Statistics',
      description: 'Gamble Beast Statistics is an HTML solution (XML and JSON feeds as well), providing historical data for up to 20 years, covering up to 25 sports across 100+ countries and over 1,900 sports events worldwide.',
      image: 'statistics',
    },
    {
      title: 'Live animation',
      description: 'We design solutions to make live betting much more engaging and entertaining. Thanks to our live animation your customer will enjoy live animations of all the match for a wide range of sports, stimulating them to place more bets.',
      image: 'animation',
    },
    {
      title: 'Live score & live calendar',
      description: 'Let your players track the scores of finished and ongoing games right on your website thanks to our Live score service, covering 15 sports, 1000+',
      image: 'score',
    },
    {
      title: 'Live streaming',
      description: 'Let\'s make the live content accessible to the worldwide audience with the Live Streaming from Gamble Beast. Turn the betting process into an entertaining and enjoyable activity.',
      image: 'streaming',
      hasSubtract: true,
    },
    {
      title: 'Odds feed',
      description: 'The uninterrupted Odds Feed is the primary component of a smart betting process. Through the Odds Feed generated by Gamble Beast the player can access to the most precise odds.',
      image: 'odds',
    }
  ],
}