
const SportBook = ({ sportsBookSections }) => {
  return (
    <div className="sportsbook">
      {sportsBookSections.map(({ checkRender, components, primary }, index) => (
        checkRender && <div key={index} className={`section ${primary ? 'primary' : ''}`}>
          {components.map(({ Compoenent, props }, index) => <Compoenent key={index} {...props} />)}
        </div>
      ))}
    </div>
  );
};

export default SportBook;
