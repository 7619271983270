const Input = ({
  type,
  form,
  name,
  placeholder,
  textarea,
  onChnage,
}) => {
  return (
    <div className="input">
      {textarea
        ? (
          <textarea
            onChange={onChnage}
            name={name}
            value={form[name] || ''}
            placeholder={placeholder || ''}
          />
        )
        : (
          <input
            onChange={onChnage}
            name={name}
            value={form[name] || ''}
            type={type || 'text'}
            placeholder={placeholder || ''}
          />
        )
      }
    </div>
  );
};

export default Input;
