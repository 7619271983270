const Modal = ({ isOpen, children }) => {
  if (!isOpen) {
    return null;
  }
  return (
    <div className="modal-wrapper">
      <div className="modal">{children}</div>
    </div>
  );
};

Modal.Header = ({ onClose }) => {
  return (
    <div className="modal-header">
      {onClose && <i className="icon-cross" onClick={onClose} />}
    </div>
  );
};
Modal.Body = ({ children }) => {
  return <div className="modal-body">{children}</div>;
};

export default Modal;
