import Benefits from "../common/Benefits";
import Cards from "../common/Cards";

const ProductSpecifications = ({ data: { specifications, benefits } }) => {
  return (
    <div className="product-specifications">
      <Cards cards={specifications} />
      <div className="product-specifications-benefits">
        <div className="container">
          <Benefits benefits={benefits} />
        </div>
      </div>
    </div>
  );
};

export default ProductSpecifications;
