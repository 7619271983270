const Button = ({ text, primary, className, link, icon, download, onClick, disable }) => {
  const classNamesButton = [];
  if (className) classNamesButton.push(className);
  if (primary) classNamesButton.push('primary');
  return (
    link ? (
      <a
        className={`button ${classNamesButton.join(' ')}`}
        href={link}
        download={download}
      >
        {icon && <i className={`icon-${icon}`} />}
        <span>{text}</span>
      </a>
    ) : (
      <button
        disabled={disable}
        className={`button ${classNamesButton.join(' ')}`}
        type="button"
        onClick={onClick || (() => {})}
      >
         {icon && <i className={`icon-${icon}`} />}
        <span>{text}</span>
      </button>
    )
  );
};

export default Button;
