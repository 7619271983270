import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Button from '../Button';

const CardItem = ({ card: { icon, title, description: { text, bold }, hasButton, buttonText, link, targetBlank } }) => {
  const history = useHistory();

  const handleClick = () => {
    if (!link) return;
    if (link.startsWith('http')) {
      if (targetBlank) {
        window.open(link, '_blank');
      } else {
        window.location.href = link;
      }
    } else {
      history.push(link);
    }
  };

  return (
    <div className="card-item">
      {(icon || title) && (
        <div className="header">
          <i className={`icon-${icon}`} />
          {title && (
            <span className="title">{title}</span>
          )}
        </div>
      )}
      <div className="body">
        <p className={`line-height ${bold ? 'bold' : ''}`}>{text}</p>
      </div>
      {hasButton && (
        <div className="footer">
          <Button text={buttonText} onClick={handleClick} />
        </div>
      )}
    </div>
  );
};

export default CardItem;
