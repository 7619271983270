import Benefits from '../common/Benefits';
import Features from '../common/Features';
import Providers from '../common/Providers';

const Advantages = ({ data: { features, benefits, providers }, concated }) => {
  return (
    <div className="advantages">
      <div className="container">
        <div className="advantages-inner">
          {features && <Features features={features} />}
          {providers && <Providers />}
          {benefits && <Benefits concated={concated} benefits={benefits}/>}
        </div>
      </div>
    </div>
  )
};

export default Advantages;
