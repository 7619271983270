import { Redirect, Route, Switch } from 'react-router';
import Home from '../../pages/Home';
import SportBook from '../../pages/Sportsbook';
import Casino from '../../pages/Casino';
import Platform from '../../pages/Platform';
import ScrollToTop from '../ScrollToTop';
import { homeSections } from '../../pages/Home/renderConfig';
import { sportsBookSections } from '../../pages/Sportsbook/renderConfig';
import { casinoSections } from '../../pages/Casino/renderConfig';
import { platformSections } from '../../pages/Platform/renderConfig';
import Company from '../../pages/Company';
import { companySections } from '../../pages/Company/renderConfig';

const Routes = () => {
  return (
    <ScrollToTop>
      <Switch>
        {homeSections.length > 0 && (
          <Route exact path="/">
            <Home homeSections={homeSections} /> 
          </Route>
        )}
        {sportsBookSections.length > 0 && (
          <Route exact path="/sportsbook">
            <SportBook sportsBookSections={sportsBookSections} /> 
          </Route>
        )}
        {casinoSections.length > 0 && (
          <Route exact path="/casino">
            <Casino casinoSections={casinoSections} /> 
          </Route>
        )}
        {platformSections.length > 0 && (
          <Route exact path="/platform">
            <Platform platformSections={platformSections} /> 
          </Route>
        )}
        {companySections.length > 0 && (
          <Route exact path="/company">
            <Company companySections={companySections} /> 
          </Route>
        )}
        <Redirect to="/" />
      </Switch>
    </ScrollToTop>
  );
};

export default Routes;
