const Features = ({ features }) => {
  return (
    <div className="features">
      {features.map(({ number, text }) => (
        <div className="feature" key={text}>
          <h3>{number}</h3>
          <h4>{text}</h4>
        </div>
      ))}
    </div>
  );
};

export default Features;
