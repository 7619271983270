import SectionTitle from '../common/SectionTitle';

const Payments = ({ data }) => {
  return (
    <div className="payments">
      <SectionTitle
        commonText={data.title}
        highlighText={data.heightLight}
      />
      <div className="payment-list-wrapper">
        <div className="payments-list">
          {data.payments.map(item => (
            <div className="payment-item" key={item}>
              <img src={`/assets/images/Payments/${item}.png`} alt={item} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Payments;
