import StepItem from './StepItem';

const Steps = ({ steps }) => {
  return (
    <div className="steps">
      <div className="container">
        <div className="steps-inner">
          {steps.map((step, idx) => <StepItem key={step.title} step={{ idx, ...step }} /> )}
        </div>
      </div>
    </div>
  );
};

export default Steps;
