import { useState, createContext } from 'react';
import Footer from './components/Footer';
import Header from './components/Header';
import Routes from './components/Routes';
import { useResizeHandler } from './hooks/useResizeHandler';

export const WindowSizeContext = createContext();

const WindowSize = ({ children }) => {
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);

  const handleResize = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };
  
  useResizeHandler(handleResize, 500);

  return (
    <WindowSizeContext.Provider value={{ width, height }}>
      {children}
    </WindowSizeContext.Provider>
  );
};

const App = () => {
  return (
    <WindowSize>
      <div className="app">
        <Header />
        <Routes />
        <Footer />
      </div>
    </WindowSize>
  );
};

export default App;
