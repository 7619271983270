const StepItem = ({ step: { idx, title, description } }) => {
  return (
    <div className="step-item">
      <span>{String((idx + 1)).padStart(2, 0)}</span>
      <h4>{title}</h4>
      {Array.isArray(description)
        ? (
          <div className="description-wrapper">
            {description.map((item, idx) => (
              <div className="description-item" key={idx}>
                <div />
                <span className="line-height">{item}</span>
              </div>
            ))}
          </div>
        )
        : (
          <p className="line-height">{description}</p>
        )}
    </div>
  );
};

export default StepItem;
