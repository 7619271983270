export const contactUs = {
  title: 'Contact Us',
  contacts: [
    { icon: 'email', contact: 'sales@gamblebeast.com', attr: 'mailto:sales@gamblebeast.com' },
  ],
};

export const findUs = {
  title: 'Find us',
  socials: [
    { icon: 'linkdin', link: 'https://www.linkedin.com/company/gamblebeast/', },
    { icon: 'facebook', link: 'https://www.facebook.com/GambleBeast', },
    { icon: 'instagram', link: 'https://www.instagram.com/gamblebeast.gb/', },
  ],
};

export const getProductInfo = {
  title: 'Get Product Info',
  button: {
    text: 'PDF',
    icon: 'engaging',
    link: '/assets/documents/document.pdf',
    primary: false,
    download: true,
  }
};

export const copyright = {
  text: '© 2022 All rights reserved |',
  highlighText: 'GambleBeast',
}