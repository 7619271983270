const FindUs = ({ data: { title, socials } }) => {
  return (
    <div className="find-us">
      <h3 className="title">{title}</h3>
      <div className="links">
        {socials.map(({ icon, link }) => (
          <a rel="noreferrer" target="_blank" key={icon} href={link}><i className={`icon-${icon}`} /></a>
        ))}
      </div>
    </div>
  );
};

export default FindUs;
