import { Link } from "react-router-dom";

const KeyInfo = ({ data }) => {
  return (
    <div className="key-info">
      <div className="header">
        <h4>{String(data.index + 1).padStart(2, 0)}</h4>
        {data?.cost && <div className="cost"><span>{data.cost}</span></div>}
      </div>
      <div className="body">
        <h3>{data.title}</h3>
        {data?.link && <Link to={data.link}><i className="icon-Vector-19" /></Link>}
      </div>
      <p className="line-height">{data.description}</p>
    </div>
  );
};

export default KeyInfo;
