export const carouselObj = {
  carousel: [
    {
      title: 'Our',
      heightLight: 'company',
      subTitle: 'Gamble Beast is a market leading provider of high-tech and flexible solutions to the sports betting and gaming industry operators, supporting both the online and the land-based operations.',
      listOfFeatures: [
        'We are always happy to discuss your specific needs and design a solution aimed at your absolute success and profitability!',
        'We are a team of experienced professionals operating in the iGaming industry since 2000 and providing our partners with a comprehensive range of top-notch products.',
        'We definitely know what it takes to create, operate and maintain a profitable gaming business.',
      ],
      button: {
        text: 'Contact Us',
        type: 'Contact',
      }
    },
  ]
};

export const productsObj = {
  title: 'Company',
  heightLight: 'values',
  products: [
    {
      icon: 'partnership', title: 'Partnership',
      description: {
        text: 'We believe in partnership, our clients are our partners and we always win together',
        bold: false,
      },
      hasButton: false
    },
    {
      icon: 'innovation', title: 'Innovation',
      description: {
        text: 'Innovative approach is the impetus for the growth and prosperity we deliver to our partners',
        bold: false,
      },
      hasButton: false
    },
    {
      icon: 'digitalization', title: 'Digitalization',
      description: {
        text: 'We integrate digital technologies to everyday life and business both for business growth and ecological concerns as a socially responsible company',
        bold: false,
      },
      hasButton: false
    },
    {
      icon: 'quality', title: 'Quality',
      description: {
        text: 'Quality is the integral feature of our every act: production, cooperation, communication, customer care',
        bold: false,
      },
      hasButton: false
    },
  ],
};

export const advantagesObj = {
  title: 'Our professional',
  heightLight: 'team',
  features: [
    { number: '+300', text: 'Traders' },
    { number: '+35', text: 'Highly qualified software developers' },
    { number: '+25', text: 'Professional risk managers' },
    { number: '+20', text: 'Years of experience' },
  ],
};

export const greatThingsObj = {
  title: 'Our approach',
  greatThings: [
    {
      description: {
        text: 'Instead of clients we have partners, thus we have partners, thus we only win together. The business growth and revenue increase of our partners are the primary goal for us.',
        bold: false,
      }
    },
    {
      description: {
        text: 'Instead of ordinary Customer support we deliver true Customer Care to our partners, so that\'s why we tailor our solutions for each partner individually!',
        bold: false,
      }
    },
    {
      description: {
        text: 'We always provide the most advanced solutions and technologies packed with simplicity.We will help you leverage your business enthusiasm and build upon your own operations.',
        bold: false,
      }
    }
  ],
};