
export const carouselObj = {
  carousel: [
    {
      image: 'banner-1',
      title: 'Meet our',
      heightLight: 'casino',
      subTitle: 'Entertain your players with the inviting content of Gamble Beast Casino solution and benefit from our profitable technology to boost your revenues.',
      button: {
        text: 'View Demo',
        primary: true,
        link: 'https://demo.gamblebeast.com/casino/games',
        targetBlank: true,
      }
    },
    {
      image: 'banner-2',
      title: 'Meet our',
      heightLight: 'casino',
      subTitle: '70+ online Casino games providers',
      button: {
        text: 'View Demo',
        primary: true,
        link: 'https://demo.gamblebeast.com/casino/games',
        targetBlank: true,
      }
    },
    {
      image: 'banner-3',
      title: 'Meet our',
      heightLight: 'casino',
      subTitle: 'Proprietary Casino widgets',
      button: {
        text: 'View Demo',
        primary: true,
        link: 'https://demo.gamblebeast.com/casino/games',
        targetBlank: true,
      }
    },
    {
      image: 'banner-4',
      title: 'Meet our',
      heightLight: 'casino',
      subTitle: 'Mobile Compatibility',
      button: {
        text: 'View Demo',
        primary: true,
        link: 'https://demo.gamblebeast.com/casino/games',
        targetBlank: true,
      }
    },
  ]
};

export const productsObj = {
  title: 'Why choose our',
  heightLight: 'casino',
  subTitle: 'It\'s simply created to boost your profits via entertaining your players for long and pleasant gambling.',
  products: [
    {
      icon: 'engaging', title: 'Engaging content',
      description: {
        text: 'Entertainment and high level satisfaction of your players by our slots, table games and live casino',
        bold: false,
      },
      hasButton: false
    },
    {
      icon: 'profitability', title: 'Profitability',
      description: {
        text: 'Robust engine geared towards high profitability thanks to inviting Bonus engine and strong Back office',
        bold: false,
      },
      hasButton: false
    },
    {
      icon: 'expertise',
      title: 'High quality and proven HTML5 3rd party games',
      description: {
        text: 'Premium HTML5 games with stunning art and innovative gameplay designed to attract, entertain and retain players’ excitement.',
        bold: false,
      },
      hasButton: false
    },
    {
      icon: 'reporting', title: 'Reporting',
      description: {
        text: 'Both standard and custom reports based on your business needs',
        bold: false,
      },
      hasButton: false
    },
  ],
};

export const advantagesObj = {
  title: 'Features &',
  heightLight: 'benefits',
  subTitle: 'Gamble Beast Sportsbook is built to bring benefits both to operators and their players',
  features: [
    { number: '+5000', text: 'Casino Games' },
  ],
  benefitsLeft: [
    'Quick start and seamless operation',
    'High-tech solution packed with the power of simplicity',
    'Advanced back office and inviting bonus engine',
    'Customizable odds and matches based on your market needs',
  ],
  benefitsRight: [
    'Classic slots from Microgaming, Tom Horn, NYX, Worldmatch, Spinomenal',
    '20+ Live dealer games, half of them on HTML5 with HD graphics',
    'Highly demanded table games from BetSoft, Microgaming and NYX',
    'Multilingual games with multiple game modes',
    'Constant portfolio increase',
  ],
};

export const partnersObj = {
  title: 'Slot',
  heightLight: 'providers',
  partners: [
    'endorphina',
    'novomatic',
    'tomhorn',
    'evolution',
    'mascot',
    'spinomenal',
    'egt',
    'betsolutions',
    'netgamig',
    'wazdan',
    'vtvbet',
    'mrslotty',
    'dragongaming',
  ]
};

export const productSpecificationsObj = {
  title: 'Product specifications',
  specifications: [
    {
      icon: 'fast',
      description: {
        text: 'Fast deployment',
        bold: true,
      }
    },
    {
      icon: 'partnership',
      description: {
        text: 'API integration',
        bold: true,
      }
    },
    {
      icon: 'performing',
      description: {
        text: 'High- Performing servers',
        bold: true,
      }
    },
    {
      icon: 'certified',
      description: {
        text: 'Certified RNG',
        bold: true,
      }
    },
  ],
  benefitsLeft: [
    'Compatible with all browsers supporting HTML5',
    'User friendly and multi-lingual user interface',
    'Various payment means and channels with adjustable exchange rates',
  ],
};

export const keyComponentsObj = {
  title: 'Key',
  heightLight: 'components',
  keyComponents: [
    {
      title: 'Live casino',
      description: 'Just enjoy the widest choice Live Casino games, the best quality streaming and provide a great player experiene with our Live Casino integration.',
      image: 'live-casino',
      hasSubtract: true,
    },
    {
      title: 'Slots',
      description: 'We introduce the best games providers with their fair and safe gaming, quality products and an exellent game variety!',
      image: 'slots',
    },
    {
      title: 'Virtual sports',
      description: 'Our Virtual Sports products are designed to deliver the outstanding user experience across all platforms, channel and devices. We offer a full range of markets, including horce racing, football, tennis, basketball etc.',
      image: 'virtual-sports',
    },
    {
      title: 'Quick games',
      description: 'Give your players an apportunity to play the most exciting and funny games in your platform. We offer easy-to-play and nice winning lottery experience to your clients.',
      image: 'quick-games',
      hasSubtract: true,
    },
    {
      title: 'Skill Games',
      description: 'Just give the opportunity to your players play and enjoy the rush that comes with alucky turn of events for real money and control the action to some extent.',
      image: 'skill-games',
    }
  ],
}