const Providers = () => {
  return (
    <div className="providers">
      <div className="container">
        <div className="providers-inner">
          <div className="images-wrapper">
            {Array.from({ length: 14 }).map((_, index) => (
              <img
                key={index}
                src={`/assets/images/Providers/${index}.png`}
                alt="provider"
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Providers;
